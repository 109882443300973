export const useFormAttrs = () => {
  const { t } = useI18n()

  const confirmPasswordAttrs = computed(() => getPasswordAttrs({ placeholder: t('log_in_confirm_pwd_placeholder') }))

  const emailAttrs = computed(() => {
    return {
      placeholder: t('log_in_account_placeholder'),
      type: 'email',
    }
  })

  const getPasswordAttrs = ({ placeholder }: { placeholder?: string } = {}) => {
    return {
      placeholder: placeholder || t('log_in_pwd_placeholder'),
      type: 'password',
      'show-password-on': 'click',
    }
  }

  return { confirmPasswordAttrs, emailAttrs, getPasswordAttrs }
}
