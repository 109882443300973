<template>
  <div class="password-strenth-bar flex w-full items-center">
    <span class="text-xs">{{ $t('pwd_strenth_title') }}</span>
    <div class="flex items-center w-9/12 gap-x-1 mx-10px">
      <div v-for="(_, i) in 5" :key="i" class="h-2px w-1/5" :class="barClass(i)"></div>
    </div>
    <span>{{ strengthWord }}</span>
  </div>
</template>
<script lang="ts" setup>
import { zxcvbn } from '@zxcvbn-ts/core'

const props = withDefaults(
  defineProps<{
    password: string
  }>(),
  {
    password: '',
  }
)

const { t } = useI18n()

const score = computed(() => {
  if (!props.password) return -1
  return zxcvbn(props.password).score
})

const barClass = (index: number) => {
  if (index <= score.value) {
    if (score.value < 2) return 'bg-red-50'
    if (score.value < 4) return 'bg-yellow-50'
    return 'bg-green-50'
  }
  return 'bg-gray-40'
}

const strengthWord = computed(() => {
  if (!props.password) return t('all_none')
  if (score.value < 2) return t('pwd_strenth_low')
  if (score.value < 4) return t('pwd_strenth_medium')
  return t('pwd_strenth_high')
})
</script>
